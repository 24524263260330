<script>
import {mapGetters} from "vuex";
import SimpleTable from "./simple-table.vue";
import TransitionToggle from "../transitions/toggle.vue";
import Swal from "sweetalert2";
import RequiredRepetitionForm from "../forms/required-repetition-form.vue";

export default {
    name: "required-repetition-manager",

    components: {RequiredRepetitionForm, TransitionToggle, SimpleTable},

    props: {
        targetType: {
            type: String,
            required: true
        },

        targetId: {
            type: Number,
            required: true
        },

        targetStore: {
            type: String
        },

        extraFields: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    computed: {
        ...mapGetters({
            items: 'requiredRepetition/all'
        }),

        fields: function () {
            return [
                {
                    key: 'target',
                    label: this.$t('interactions::required_repetitions.relationships.target').ucFirst(),
                    formatter: (value) => value?.translation_key ? this.$t(value.translation_key) : value?.name
                },
                {
                    key: 'interaction',
                    label: this.$t('interactions::required_repetitions.relationships.interaction').ucFirst(),
                    formatter: (value) => value?.translation_key ? this.$t(value.translation_key).ucFirst() : value?.name,
                },
                {
                    key: 'period',
                    label: this.$t('interactions::required_repetitions.columns.period').ucFirst(),
                    formatter: (value, index, item) => item.number + ' ' + this.$t(`dates.${value}`)
                },
                {
                    key: 'time',
                    label: this.$t('interactions::required_repetitions.columns.time').ucFirst(),
                },
                ...this.extraFields,
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    tdClass: 'text-end'
                }
            ];
        },
    },

    data: function () {
        return {
            showForm: false,
            item: {
                target_id: this.targetId,
                target_type: this.targetType,
            },
        }
    },

    methods: {
        editTableItem: function (item, callback) {
            this.$store.dispatch('requiredRepetition/setItem', item).then(() => {
                callback(item)
            })
        },

        removeTableItem: function (item) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('requiredRepetition/destroy', item.id)
                }
            });
        },

        updateTableItem: function (item, callback) {
            item.query = {with: ['target', 'interaction']}
            this.$store.dispatch('requiredRepetition/update', item).then(() => {
                this.$store.dispatch('requiredRepetition/clearItem')
                callback()
            })
        },

        add: function (item) {
            this.$store.dispatch('requiredRepetition/create', item).then(() => {
                this.$store.dispatch(`${this.store}/clearItem`)
                this.showForm = false
            })
        },

        clearItem: function () {
            this.item = {
                target_id: this.targetId,
                target_type: this.targetType,
            }
            this.$store.dispatch('requiredRepetition/clearItem')
        },

        copyToClipboard: function (value) {
            navigator.clipboard.writeText(value);
        },
    },

    created() {
        this.$store.dispatch('requiredRepetition/all', {target_id: this.targetId, target_type: this.targetType, with: ['target', 'interaction']})
        this.$store.dispatch('interaction/all')
        if (this.targetStore) {
            this.$store.dispatch(`${this.targetStore}/all`);
        }
    }
}
</script>

<template>
    <div class="lead-source-commodities-manager">
        <simple-table :items="items" :fields="fields">
            <template #actions="{item, edit}">
                <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="editTableItem(item, edit)">
                    <i class="fas fa-edit"></i>
                </span>
                <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="removeTableItem(item)">
                    <i class="fas fa-trash-alt"></i>
                </span>
            </template>
            <template #default="{close, item}">
                <required-repetition-form :item="item" do-not-save :target-id="targetId" :target-type="targetType" :target-store="targetStore" @update="updated => updateTableItem(updated, close)">
                    <template #buttons>
                        <b-button class="ms-2" variant="outline-secondary" @click="close">{{ $t('base.cancel').ucFirst() }}</b-button>
                    </template>
                </required-repetition-form>
            </template>
            <template #user="{ value }">
                <div class="avatar-group-item">
                    <a v-if="value?.image && value?.image?.path" href="javascript: void(0);" class="d-inline-block">
                        <img :src="value.image.path"
                             :alt="value.name"
                             :title="value.name"
                             class="rounded-circle avatar-sm">
                    </a>
                    <div v-else class="avatar-sm cursor-pointer">
                        <span :title="value?.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ value?.name?.firstLetters() }} </span>
                    </div>
                </div>
            </template>
            <template #url="{value}">
                <span class="cursor-pointer hover" @click="copyToClipboard(value)" :title="$t('base.copy_to_clipboard').ucFirst()">{{ value }}</span>
            </template>
        </simple-table>
        <transition-toggle>
            <required-repetition-form v-if="showForm" do-not-save :target-id="targetId" :target-type="targetType" :target-store="targetStore" class="border rounded p-3 bg-white" :item="item" @add="add">
                <template #buttons>
                    <b-button class="ms-2" variant="outline-secondary" @click="showForm = !showForm">{{ $t('base.cancel').ucFirst() }}</b-button>
                </template>
            </required-repetition-form>
        </transition-toggle>
        <transition-toggle>
            <div class="col-12 mb-2 text-center" v-if="!showForm">
                <b-button variant="success" @click="showForm = !showForm">{{ $t('base.add_new').ucFirst() }}</b-button>
            </div>
        </transition-toggle>
        <div class="row">
            <div class="col-12">
                <p></p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
