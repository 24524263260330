<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <company-size-form :company-size="companySize">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </company-size-form>
                </div>
            </div>
        </div>
        <div class="col-lg-12" v-if="companySize.id">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $tc('interactions::interactions.interaction', 2).ucFirst() }}: {{ $tc('interactions::required_repetitions.required_repetition', 2).ucFirst() }}</h4>
                </div>
                <div class="card-body bg-light">
                    <required-repetition-manager
                        :target-id="companySize.id"
                        target-type="Appon\Partners\Models\CompanySize"
                        target-store="companySize"
                    >
                    </required-repetition-manager>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CompanySizeForm from "../../../components/forms/company-size-form.vue";
import CallbackButton from "../../../components/elements/callback-button.vue";
import RequiredRepetitionManager from "../../../components/elements/required-repetition-manager.vue";

export default {
    components: {RequiredRepetitionManager, CallbackButton, CompanySizeForm},

    computed: {
        ...mapGetters({
            companySize: 'companySize/item'
        }),

        title: function () {
            return this.companySize.id ? this.$t('partners::company_sizes.company_size', 2).ucFirst() + ' / ' + this.companySize.name : this.$t('base.create_new_item', {item: this.$tc('partners::company_sizes.company_size', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/master-data/company-sizes')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: {
            immediate: true,
            handler: function (value) {
                this.$store.dispatch('app/title', {key: value})
            }
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('companySize/show', {id: this.$route.params.id})
        }
    },

    unmounted() {
        this.$store.dispatch('companySize/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
