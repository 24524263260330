<script>
import {mapGetters} from "vuex";

export default {
    name: "required-repetition-form",

    props: {
        item: {
            type: Object,
            required: true
        },

        targetType: {
            type: String
        },

        targetId: {
            type: String
        },

        targetStore: {
            type: String
        },

        doNotSave: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            loading: 'item/loading',
            states: 'item/states',
            errors: 'item/errors',
            interactions: 'interaction/all'
        }),

        targets: function () {
            return this.$store.getters[`${this.targetStore}/all`]
        },

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        options: function () {
            return ['day', 'week', 'month', 'year'].map(str => {
                return {
                    value: str,
                    text: this.$t('dates.' + str)
                }
            })
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({query: {with: ['interaction']}}, this.model)

            if (!this.doNotSave) {
                return this.$store.dispatch(`requiredRepetition/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    /*if (method === 'create' && this.redirect) {
                        const pathType = this.own ? '/items/my-items' : (this.accessible ? '/items/accessible-items' : '/items')
                        this.$router.push(this.$t('routes.' + pathType + '/:id(\\d+)', {id: this.model.id}).rmValidation())
                    }*/
                })
            } else {
                if (this.model.id) {
                    this.$emit('update', this.model);
                } else {
                    this.$emit('add', this.model);
                }
            }
        },

        setModel: function (model = {}) {
            const base = {}

            if (this.targetType) {
                base.target_type = this.targetType
            }

            if (this.targetId) {
                base.target_id = this.targetId
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));
        },
    },

    watch: {
        item: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.setModel(value)
            },
        },
    },

    unmounted() {
        this.$store.dispatch('requiredRepetition/clearItem')
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3 mb-4" v-if="!targetId">
                    <label
                        :class="{'text-danger': states.target_id === false}"
                        class="form-label"
                        for="target_id-input"
                    >{{ $t('interactions::required_repetitions.relationships.target').ucFirst() }}</label
                    >
                    <select
                        id="target_id-input"
                        v-model="model.target_id"
                        :class="{'border-danger': states.target_id === false || states.target_type === false}"
                        class="form-select"
                        name="target_id"
                    >
                        <option :value="model.id === undefined ? undefined : null">
                            {{ $t('interactions::required_repetitions.placeholders.target_id').ucFirst() }}
                        </option>
                        <option v-for="(item, index) in targets" :key="index" :value="item.id">
                            {{ item.translation_key ? $t(item.translation_key).ucFirst() : item.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.target_id === false || states.target_type === false}" class="invalid-feedback">{{ errors.target_id }} {{ errors.target_type }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.interaction_id === false}"
                        class="form-label"
                        for="interaction_id"
                    >{{ $t('interactions::required_repetitions.columns.interaction_id').ucFirst() }}</label>
                    <select
                        id="interaction_id"
                        v-model="model.interaction_id"
                        :class="{'border-danger': states.interaction_id === false}"
                        class="form-select"
                        name="interaction_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('interactions::required_repetitions.placeholders.interaction_id').ucFirst() }}
                        </option>
                        <option v-for="(item, key) in interactions" :key="key" :value="item.id">{{
                                item.translation_key ? $t(item.translation_key).ucFirst() : item.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.interaction_id === false}" class="invalid-feedback">
                        {{ errors.interaction_id }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.number === false}"
                        class="form-label"
                        for="number"
                    >{{ $t('interactions::required_repetitions.columns.number').ucFirst() }}</label
                    >

                    <input
                        id="number"
                        v-model="model.number"
                        :class="{'border-danger': states.number === false}"
                        :placeholder="$t('interactions::required_repetitions.placeholders.number').ucFirst()"
                        class="form-control"
                        type="number"
                    />
                    <div :class="{'d-block': states.number === false}" class="invalid-feedback">{{ errors.number }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.period === false}"
                        class="form-label"
                        for="period"
                    >{{ $t('interactions::required_repetitions.columns.period').ucFirst() }}</label>
                    <select
                        id="period"
                        v-model="model.period"
                        :class="{'border-danger': states.period === false}"
                        class="form-select"
                        name="period"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('interactions::required_repetitions.placeholders.period').ucFirst() }}
                        </option>
                        <option v-for="(item, key) in options" :key="key" :value="item.value">{{
                                item.text
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.period === false}" class="invalid-feedback">
                        {{ errors.period }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.time === false}"
                        class="form-label"
                        for="time"
                    >{{ $t('interactions::required_repetitions.columns.time').ucFirst() }}</label
                    >

                    <input
                        id="time"
                        v-model="model.time"
                        :class="{'border-danger': states.time === false}"
                        :placeholder="$t('interactions::required_repetitions.placeholders.time').ucFirst()"
                        class="form-control"
                        type="number"
                    />
                    <div :class="{'d-block': states.time === false}" class="invalid-feedback">{{ errors.time }}</div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-12 text-center">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
